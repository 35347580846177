import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import useStore from '../../store';
import ModalBasic from '../ModalBasic/ModalBasic';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const POLLING_INTERVAL = 1 * 60 * 1000;
const fetcher = (...args) => fetch(...args).then(res => res.json());

function ServerVersionChecker() {
  const [showUpdatePrompt, setShowUpdatePrompt] = useState(false);
  const { version, setVersion } = useStore();
  const { t } = useTranslation();

  const { data } = useSWR('/api/version/', fetcher, {
    refreshInterval: POLLING_INTERVAL,
    revalidateOnFocus: false,
    onSuccess: (data) => console.log('SWR polling succeeded:', data)
  });

  useEffect(() => {
    console.log('useEffect triggered - data:', data, 'current version:', version);
    if (data?.version) {
      if (version === null) {
        console.log('First load - just store the version', data.version);
        setVersion(data.version);
      } else if (version !== data.version) {
        console.log('Version changed - show update prompt');
        setShowUpdatePrompt(true);
      } else {
        console.log('Version is the same - do nothing', data.version,'===', version);
      }
    }
  }, [data, version, setVersion]);

  const footerButtons = (
    <>
      <Button color="primary" onClick={() => window.location.reload()}>
        {t('Refresh Now')}
      </Button>
      <Button color="secondary" onClick={() => setShowUpdatePrompt(false)}>
        {t('Dismiss')}
      </Button>
    </>
  );

  return (
    <ModalBasic
      isOpen={showUpdatePrompt}
      toggle={() => setShowUpdatePrompt(false)}
      title={t('Update Available')}
      size="sm"
      footerButtons={footerButtons}
    >
      {t('A new version is available. Please refresh to update.')}
    </ModalBasic>
  );
}

export default ServerVersionChecker;
