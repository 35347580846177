import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import useStore from '../../store';
import FlexLoader from '../../components/FlexLoader/FlexLoader';
import ResourcesTable from '../../components/ResourcesTable/ResourcesTable';
import apiService from '../../utils/apiService';
import Dropzone from '../../components/Dropzone/Dropzone';
import { StyledHeader } from './ResourcesPage.styles';
import GeneralDropDown from '../../components/GeneralDropDown/GeneralDropDown';


function ResourcesPage() {
  const { t } = useTranslation();
  const { loggedIn, workspace, resources } = useStore((state) => ({
    loggedIn: state.loggedIn,
    workspace: state.workspace,
    resources: state.resources,
  }));
  const metadata = useMemo(() => ({
    workspace_id: workspace?.id,
  }), [workspace]);

  const onDrop = async (acceptedFiles) => {
    
    for (const file of acceptedFiles) {
      try {
        console.log('Starting upload process for:', file.name);
        
        // Step 1: Get signed URL
        const getUrlResponse = await apiService.post('/api/resources/get-upload-url/', {
          fileName: file.name,
          contentType: file.type
        });

        if (!getUrlResponse.success) {
          console.error('Failed to get upload URL:', getUrlResponse.message);
          continue;
        }

        const { signedUrl, resourceId, fileName } = getUrlResponse.data;
        console.log('Got signed URL for:', fileName);

        // Step 2: Upload to GCS
        const uploadResponse = await fetch(signedUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type
          }
        });

        if (!uploadResponse.ok) {
          console.error('Failed to upload to GCS:', uploadResponse.statusText);
          continue;
        }

        console.log('File uploaded to GCS, completing process...');

        // Step 3: Complete upload - Convert metadata to JSON string
        const completeResponse = await apiService.post('/api/resources/complete-upload/', {
          resourceId: resourceId,
          fileName: fileName,
          title: file.name,
          metadata: JSON.stringify(metadata)  // Convert to JSON string
        });

        if (completeResponse.success) {
          console.log(`File ${file.name} upload completed:`, completeResponse.data);
        } else {
          console.error('Failed to complete upload:', completeResponse.message);
        }

      } catch (error) {
        console.error(`Error uploading ${file.name}:`, error);
      }
    }
  };

  const pageTitle = useMemo(() => {
    const resourcesLabel = t('Resources');
    if(!workspace) return resourcesLabel;
    return `${resourcesLabel} - ${workspace.name}`;
  }, [t, workspace]);

  /*
  const resyncResources = useCallback(async () => {
    try {
      const response = await apiService.post(`/api/workspaces/${workspace.id}/resources/resync/nuclia/`, {});
      if (response.success) {
        console.log('Workspace resources resynced successfully', response.data);
      } else {
        console.error('Resync failed:', response.message);
      }
    } catch (error) {
      console.error('Error during workspace resources resync:', error);
    }
  }, [workspace]);

  const dropdownItems = useMemo(() => [
    { label: 'Resync Resources', onClick: resyncResources },
  ], [resyncResources]);*/



  const dropdownItems = undefined;


  if(!loggedIn) return (<Row><Col><h2>{pageTitle}</h2><FlexLoader /></Col></Row>);

  return (
    <Row>
      <Col sm="12">
        <StyledHeader className='d-flex align-items-center justify-content-between'>
          <h2>{pageTitle}</h2>
          {dropdownItems && dropdownItems.length && <GeneralDropDown dropdownItems={dropdownItems} />}
        </StyledHeader>
        <Dropzone onDrop={onDrop} metadata={metadata} />
        {resources && resources.length > 0 && <ResourcesTable resources={resources} />}
      </Col>
    </Row>
  );
}

export default ResourcesPage;
